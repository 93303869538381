<template>
  <div review-list-item>
    <div class="best-btn-holder" v-if="showBestBtn">
      <BasicButton :class="{best: bestReview, disabled: disabled}" @click="bestBtnHandler(review)">
        <SvgCheck :color="disabled? '#ddd' :  bestReview ? '#2c81ff' :'#191919'" class="check" />
        대표리뷰
      </BasicButton>
    </div>
    <div class="review-content-holder">
      <Avatar class="avatar" :info="user" :defaultImgType="'fruit'" dimension="50x50" />
      <div class="review-content">
        <p>“{{ description }}”</p>
        <div>
          <span class="nickname">{{ coveredNickname }}</span>
          <span class="divider">|</span>
          <span class="date">{{ writeDateTime }} 작성됨</span>
        </div>
      </div>
    </div>
    <div class="rating-holder">
      <span class="rating">{{ rating }}</span>
      <div class="rating-star" v-for="i in 5" :key="i">
        <span v-if="i <= rating">★</span>
        <span v-else>☆</span>
      </div>
    </div>
  </div>
</template>

<script>
import Avatar from 'shared/components/common/Avatar.vue';
import SvgCheck from 'shared/graphics/svg-check.vue';
import BasicButton from '@/views/components/common/button/BasicButton.vue';

export default {
  name: 'ReviewListItem',
  components: { BasicButton, Avatar, SvgCheck },
  props: {
    review: { default: {} },
    bestReview: { type: Boolean, default: false },
    showBestBtn: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
  },
  computed: {
    _review() {
      return /** @return {LessonReview} */ this.review;
    },
    description() {
      return this._review?.description;
    },
    user() {
      return this._review?.user;
    },
    nickname() {
      return this.user?.nickname;
    },
    coveredNickname() {
      return `${'*'.repeat(this.nickname?.length || 0) + String(this.nickname).slice(-2)}님`;
    },
    writeDateTime() {
      return this.$date(this.review?.writeDateTime, 'dot');
    },
    rating() {
      return this._review?.rating;
    },
  },
  methods: {
    bestBtnHandler(review) {
      if (this.disabled) return;
      this.$emit('click', review);
    },
  },
};
</script>

<style lang="less">
@import '~@/less/coaching.less';

[review-list-item] { .p(20); .flex; .w(100%); border: 1px solid #efefef; .br(8);
  .best-btn-holder {
    [basic-button] { border: solid 1px @black-primary; .c(@black-primary); .w(104); .h(32); .mr(24); .fs(14); .p(0);
      &.best { border: solid 1px #2f50f8; .c(#2f50f8);}
      .check {.w(16); .mr(6);}
      &.disabled {pointer-events: none; border: 1px solid #ddd; .c(#ddd); }
    }
  }
  .avatar {.min-w(50); .min-h(50); .wh(50); .mr(16); .br(16); }
  .review-content-holder {.flex;}
  .review-content { .mr(24);
    > p {.fs(16); .c(#191919); .lh(23); line-break: anywhere;}
    > div { .c(@gray-500); .fs(11); .mt(12);
      .nickname { }
      .divider { .c(@gray-300); .m(0, 10); }
      .date {}
    }
  }
  > .rating-holder {.flex; .c(#ff757d); .items-center; .l(0); .b(8); .h(24); .ml(auto);
    .rating {.chakra; .fs(12); .bold; .mr(5); .lh(16);}
    .rating-star {.fs(12); .lh(16); .bold;}
  }

  @media (@tp-down) {
    flex-direction: column;
    .best-btn-holder { .mb(12);
      [basic-button] {.h(40);}
    }
    .avatar {.wh(32); .min-w(32); .min-h(unset);}
    .review-content {
      > p {.fs(14); .lh(18);}
    }
    .rating-holder { .ml(47);}
  }
}

</style>
