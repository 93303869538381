<template>
  <div coach-detail-review>
    <section v-if="showBestReview || isEditable" ref="bestReviewSection" class="best-review">
      <div v-if="isEditable" class="edit-holder">
        <BasicButton class="edit" v-if="!editMode" @click="editBtnHandler">
          <img src="/img/icon/icon_pencil.svg">
          대표 리뷰 편집하기
        </BasicButton>
        <BasicButton class="cancel" v-if="editMode" @click="cancelBtnHandler">취소하기</BasicButton>
        <BasicButton class="confirm" theme="blue" v-if="editMode" @click="confirmBtnHandler">저장하기</BasicButton>
      </div>
      <div v-show="showBestReview" ref="bestReviewBg" class="section-bg" />
      <Heading class="heading">
        <template #title>대표리뷰</template>
        <template v-if="editMode" #sub>{{ `${bestReviewCount}개 적용중 (최대 5개)` }}</template>
      </Heading>
      <div v-if="showBestReview" class="list">
        <ReviewListItem v-for="item in bestReviewItemList" :key="item.lessonReviewId" :showBestBtn="editMode" bestReview :review="item" />
      </div>
      <div v-else class="no-review">
        <p>대표리뷰가 없습니다.<br>전체 리뷰 목록에서 대표리뷰를 설정해 보세요.</p>
      </div>
    </section>
    <section v-if="showReview || isEditable" class="review-list">
      <Heading class="heading">
        <template #title>전체리뷰</template>
        <template #sub>({{ _reviewCount }})</template>
      </Heading>
      <div class="list">
        <ReviewListItem v-for="item in reviewItemList" :disabled="checkDisabled(item)" :bestReview="checkBestReview(item)" :key="item.lessonReviewId" :showBestBtn="editMode" :review="item" @click="reviewItemHandler(item)" />
        <Pagination :chunkSize="10" :info="review" :showSize="isMobile ? 5 : 10" class="pagination" theme="coaching" @change="paginationHandler" />
      </div>
    </section>
    <div v-if="!showReview" class="no-review">
      <img alt="no-content-icon" src="/img/coaching/emoji-eyes.svg" />
      <p>아직 작성된 리뷰가 없습니다.</p>
    </div>
  </div>
</template>

<script>
import { commaDecimal } from '@shared/utils/numberUtils';
import { state, getter } from '@shared/utils/storeUtils';
import Heading from '@/views/components/common/text/Heading.vue';
import ReviewListItem from '@/views/components/coaching/ReviewListItem.vue';
import Pagination from '@/views/components/common/Pagination.vue';
import BasicButton from '@/views/components/common/button/BasicButton.vue';
import FloatEditAreaReview from '@/views/components/coaching/coach-detail/FloatEditAreaReview.vue';
import floatPopIn from '@/mixins/floatPopIn';

export default {
  name: 'CoachDetailReview',
  mixins: [floatPopIn()],
  components: { BasicButton, ReviewListItem, Heading, Pagination },
  props: {
    isEditable: { type: Boolean, default: false },
  },
  data: () => ({
    cursor: 0,
    review: {},
    bestReviewItemList: [],
    editMode: false,
    chooseReviewIdList: [],
  }),
  watch: {
    showBestReview() {
      this.$nextTick(() => {
        this.bestSectionBgPos();
      });
    },
  },
  computed: {
    myCoachId: getter('auth', 'myCoachId'),
    matchedMedia: state('browser', 'matchedMedia'),
    isMobile() {
      return this.matchedMedia?.charAt(0) === 'M';
    },
    showBestReview() {
      return !!this.bestReviewItemList?.length;
    },
    showReview() {
      return !!this.reviewItemList?.length;
    },
    reviewCount() {
      return this.review?.totalCount || 0;
    },
    bestReviewCount() {
      return this?.bestReviewItemList?.length || 0;
    },
    bestReviewIdList() {
      /** 대표리뷰 아이템의 id list */
      return this.bestReviewItemList?.reduce((acc, item) => {
        acc.push(item?.lessonReviewId);
        return acc;
      }, []);
    },
    _reviewCount() {
      return commaDecimal(this.reviewCount);
    },
    reviewItemList() {
      return this.review?.items || [];
    },
    totalBestReviewList() {
      /** 편집 모드에서 기존 대표리뷰 & 새로운 대표 리뷰 종합한 list */
      const bestReviews = this.bestReviewIdList.reduce((acc, id) => {
        if (!this.chooseReviewIdList.includes(id)) acc.push(id);
        return acc;
      }, []);
      return [...new Set([...bestReviews, ...this.newChooseBestReviews])];
    },
    newChooseBestReviews() {
      /** 선택한 새로운 대표리뷰 */
      return this.chooseReviewIdList.reduce((acc, id) => {
        if (!this.bestReviewIdList.includes(id)) acc.push(id);
        return acc;
      }, []);
    },
    coachId() {
      return this.$route.params?.coachId;
    },
  },
  methods: {
    editBtnHandler() {
      this.chooseReviewIdList = [];
      this.editMode = true;
    },
    cancelBtnHandler() {
      this.chooseReviewIdList = [];
      this.editMode = false;
    },
    async confirmBtnHandler() {
      await this.updateRepresentativeReviews();
      this.chooseReviewIdList = [];
      this.editMode = false;
      this.bestReviewItemList = await this.$services.coaching.getRepresentativeReviews({ coachId: this.coachId });
    },
    async updateRepresentativeReviews() {
      const lessonReviewIdList = this.totalBestReviewList;
      await this.$services.coaching.putRepresentativeReviews({ coachId: this.coachId, lessonReviewIds: lessonReviewIdList });
    },
    reviewItemHandler(item) {
      const lessonReviewId = item?.lessonReviewId;
      const idx = this.chooseReviewIdList.findIndex(id => `${id}` === `${lessonReviewId}`);
      if (idx > -1) {
        this.chooseReviewIdList.splice(idx, 1);
      } else {
        this.chooseReviewIdList.push(lessonReviewId);
      }
    },
    async getLessonReviews(cursor) {
      const coachId = this.$route.params?.coachId;
      const order = 'writeDatetime DESC';
      this.review = await this.$services.coaching.getLessonReviews({ coachId, size: 10, cursor, order }, true);
    },
    paginationHandler(cursor) {
      this.getLessonReviews(cursor);
    },
    checkDisabled(item) {
      /** 리뷰 버튼 활성화 여부 */
      const lessonReviewId = item?.lessonReviewId;
      return this.totalBestReviewList.length >= 5 && !this.totalBestReviewList.includes(lessonReviewId);
    },
    checkBestReview(item) {
      /** 대표 리뷰인지 확인 */
      const lessonReviewId = item?.lessonReviewId;
      if (this.bestReviewIdList.includes(lessonReviewId)) return !this.chooseReviewIdList.includes(lessonReviewId);
      return !!this.chooseReviewIdList.includes(lessonReviewId);
    },
    bestSectionBgPos() {
      if (!this.showBestReview) return;
      const bg = this.$refs.bestReviewBg;
      const section = this.$refs.bestReviewSection;
      const rect = section?.getBoundingClientRect();
      const scrollBarWidth = window.innerWidth - document.documentElement.clientWidth;
      bg.style.left = `${`-${rect?.x}` - scrollBarWidth}px`;
    },
    getFloatPopInOptions() {
      return ({
        componentFloat: FloatEditAreaReview,
        // data 내부의 prop이 dynamic하게 반영되지 않으므로, 대신 Vue mutation Observer가 붙어있는 data를 통째로 내려버림
        optionsFloat: { onEdit: this.editBtnHandler.bind(this), onCancel: this.cancelBtnHandler.bind(this), onSave: this.confirmBtnHandler.bind(this), data: this.$data },
        unregister: this.myCoachId !== this.coachId,
      });
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.bestSectionBgPos();
    });
    window.addEventListener('resize', this.bestSectionBgPos);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.bestSectionBgPos);
  },
  async asyncData({ route, services }) {
    const coachId = route.params?.coachId;
    const order = 'writeDatetime DESC';
    const review = await services.coaching.getLessonReviews({ coachId, size: 10, order });
    const bestReviewItemList = await services.coaching.getRepresentativeReviews({ coachId });
    return { review, bestReviewItemList };
  },
};
</script>

<style lang="less">
@import '~@/less/coaching.less';
[coach-detail-review] { .w(100%);
  .edit-holder { .rel; .p(32, 0); .z(1); .flex; .justify-center;
    .cancel {.ml(auto); .mr(12);}
    .edit {.ml(auto);}
    .confirm {}
    img {.mr(4);}
    [basic-button] {}
  }
  .best-review { .pt(32); .pb(32); .rel;
    .heading {.mb(24); .rel; .z(1); }
    .section-bg { .abs; .w(100vw); .h(100%); .bgc(#f6f6f6); .z(0); .t(0); .l(0);}
    .list {.flex; flex-direction: column; gap: 12px; .rel; .z(1);
      [review-list-item] {.bgc(#fff);
        .best-btn-holder {
          [basic-button] {pointer-events: none;}
        }
      }
    }
    .no-review {.flex; text-align: center; .h(240);
      > p {.lh(21)}
    }
  }
  .review-list { .pt(32); .pb(32);
    .heading {.mb(24); }
    .list {.flex; flex-direction: column; gap: 12px;
      .pagination {.mt(20);
        a {.ml(4); .mr(4);}
      }
    }
  }
  .no-review { .w(100%); .h(370); .flex; .justify-center; .items-center; flex-direction: column;
    > img {.mb(16);}
    > p {.fs(14); .c(#000); .o(0.8);}
  }
  @media (@tp-down) {
    .edit-holder {.hide}
  }
}

</style>
